// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-especes-marines-tsx": () => import("./../src/pages/especes-marines.tsx" /* webpackChunkName: "component---src-pages-especes-marines-tsx" */),
  "component---src-pages-especes-terrestres-tsx": () => import("./../src/pages/especes-terrestres.tsx" /* webpackChunkName: "component---src-pages-especes-terrestres-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-animal-index-tsx": () => import("./../src/templates/Animal/index.tsx" /* webpackChunkName: "component---src-templates-animal-index-tsx" */),
  "component---src-templates-page-index-tsx": () => import("./../src/templates/Page/index.tsx" /* webpackChunkName: "component---src-templates-page-index-tsx" */)
}

